import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useData } from '../contexts/DataContext'
import { Button, ButtonGroup, Box, Typography, Card, CardContent } from '@mui/material';

const CustomLineChart = () => {
    const { data, setRange } = useData();
    
    return (
        <Card>
            <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" gutterBottom sx={{ color: 'grey.600' }}>
                        Company Overview
                    </Typography>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group" size="small">
                        <Button onClick={() => setRange('weekly')}>Weekly</Button>
                        <Button onClick={() => setRange('monthly')}>Monthly</Button>
                        <Button onClick={() => setRange('yearly')}>Yearly</Button>
                    </ButtonGroup>
                </Box>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="users" stroke="#8884d8" name='New Users' />
                        <Line type="monotone" dataKey="docs" stroke="#82ca9d" name='New Documents' />
                        <Line type="monotone" dataKey="payments" stroke="#ffc658" name='Money In' />
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default CustomLineChart;
