import React, { useEffect, useState } from 'react';
import { collection, doc, onSnapshot, orderBy, query, where, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Ensure you have your Firebase config here
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
export default function UnAssignedPayoutComponent() {
   const [docs, setDocs] = useState([]);
   const [loadingButtons, setLoadingButtons] = useState({});

  useEffect(() => {
   try{
     const q = query(collection(db, 'docs'), 
    where('completed', '==', true),
    where('assignedTo', '==', ''), 
    orderBy('paidDate', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const paymentsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDocs(paymentsData);
    });

    return () => unsubscribe();
   }catch(error) {
    console.log(error)
   }
  }, []);

  const getTimeDifference = (completedAt) => {
  const currentTime = Timestamp.now();
  const completedTime = completedAt.toDate(); // Convert Firebase timestamp to JavaScript Date object

  const differenceInMilliseconds = currentTime.toMillis() - completedTime.getTime();
  
  // Convert milliseconds to a more readable format
  const seconds = Math.floor(differenceInMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Create a string representation of the time difference
  const timeDifferenceString = `${days} days, ${hours % 24} hours, ${minutes % 60} minutes ago`;

  return timeDifferenceString;
};


 const handleAssign = async (documentID) => {
  // setLoading(true);
  setLoadingButtons((prev) => ({ ...prev, [documentID]: true }));
  try {
    const loggedInUserId = auth.currentUser.uid;

    if (!documentID) {
      throw new Error('empty-value', 'Please select a document.');
    }

    const documentRef = doc(collection(db, 'docs'), documentID);

    await updateDoc(documentRef, {
      assignedTo: loggedInUserId
    });

    // Remove the document from the docs state
    setDocs((prevDocs) => prevDocs.filter((doc) => doc.id !== documentID));

    
  } catch (error) {
    console.error('Error updating document: ', error);
  } finally {
    setLoadingButtons((prev) => ({ ...prev, [documentID]: false }));
  }
};

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Actions</TableCell>
            <TableCell>Payment Amount</TableCell>
            <TableCell>Document ID</TableCell>
            <TableCell>Waiting Time</TableCell>
            <TableCell>Completed Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {docs.map((doc) => (
            <TableRow key={doc.id}>
             <TableCell>
               <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleAssign(doc.id)}
                disabled={loadingButtons[doc.id]}

                >
                {loadingButtons[doc.id] ? 'Loading...' : 'Take'}
               </Button>
              </TableCell>
              <TableCell>ZAR {doc.commission/100}</TableCell>
              <TableCell>{doc.idRefNumber}</TableCell>
              <TableCell>{getTimeDifference(doc.completedAt)}</TableCell>
              {/* {JSON.stringify(doc)} */}
              <TableCell>{doc.paidDate.toDate().toLocaleDateString()}</TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
