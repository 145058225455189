import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage'
import HomePage from './components/HomePage';
import TermsComponent from './components/TermsComponent';
import PrivacyComponent from './components/PrivacyComponent';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/contexts/AuthContext';
import { DataProvider } from './components/contexts/DataContext';

function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
            <Route path='/terms' element={<TermsComponent />} />
            <Route path='/privacy' element={<PrivacyComponent />} />
          </Routes>
        </Router>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;
