import React, { useEffect, useState } from 'react';
import { useData } from '../contexts/DataContext';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardContent, Typography } from '@mui/material';

export default function UnpaidDocumentViewStatus() {
    const { docs } = useData();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (docs.length > 0) {
            const processedData = processChartData(docs);
            setChartData(processedData);
        }
    }, [docs]);

    const processChartData = (data) => {
        const unpaidDocs = data.filter(doc => !doc.paid);
        const viewedCount = unpaidDocs.filter(doc => doc.viewed).length;
        const notViewedCount = unpaidDocs.length - viewedCount;

        return [
            { name: 'Viewed', count: viewedCount },
            { name: 'Not Viewed', count: notViewedCount }
        ];
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" gutterBottom sx={{ color: 'grey.600' }}>
                    Unpaid Document View Status
                </Typography>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={{ value: 'Number of Documents', angle: -90, position: 'insideLeft' }} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" fill="#8884d8" name="Documents" />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}
