import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from '../../firebase';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({});
    const [docs, setDocs] = useState({});
    const [range, setRange] = useState('weekly');

    useEffect(() => {
        const fetchData = async () => {
            const usersCollection = collection(db, 'users');
            const docsCollection = collection(db, 'docs');
            const docPaymentCollection = collection(db, 'docPayment');

            const now = new Date();
            let startDate;

            if (range === 'weekly') {
                startDate = new Date(now.setDate(now.getDate() - 7));
            } else if (range === 'monthly') {
                startDate = new Date(now.setMonth(now.getMonth() - 1));
            } else if (range === 'yearly') {
                startDate = new Date(now.setFullYear(now.getFullYear() - 1));
            }

            const usersQuery = query(usersCollection, where('createdAt', '>=', startDate));
            const docsQuery = query(docsCollection, where('timestamp', '>=', startDate));
            const docPaymentQuery = query(docPaymentCollection, where('createdDate', '>=', startDate));

            const usersSnapshot = await getDocs(usersQuery);
            const docsSnapshot = await getDocs(docsQuery);
            const docPaymentSnapshot = await getDocs(docPaymentQuery);

            const usersData = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            const docsData = docsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            const docPaymentData = docPaymentSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

            setData(processChartData({ usersData, docsData, docPaymentData }, range));
            setDocs(docsData);
        };

        fetchData();
    }, [range]);


    const processChartData = (data, range) => {
        const chartData = [];
        const now = new Date();

        if (range === 'weekly') {
            for (let i = 6; i >= 0; i--) {
                const date = new Date();
                date.setDate(now.getDate() - i);
                const label = date.toLocaleDateString();
                chartData.push({
                    date: label,
                    users: data.usersData.filter(user => {
                        const userDate = new Date(user.createdAt.seconds * 1000).toLocaleDateString();
                        return userDate === label;
                    }).length,
                    docs: data.docsData.filter(doc => {
                        const docDate = new Date(doc.timestamp.seconds * 1000).toLocaleDateString();
                        return docDate === label;
                    }).length,
                    payments: data.docPaymentData.filter(payment => {
                        const paymentDate = new Date(payment.createdDate.seconds * 1000).toLocaleDateString();
                        return paymentDate === label;
                    }).length,
                });
            }
        } else if (range === 'monthly') {
            for (let i = 29; i >= 0; i--) {
                const date = new Date();
                date.setDate(now.getDate() - i);
                const label = date.toLocaleDateString();
                chartData.push({
                    date: label,
                    users: data.usersData.filter(user => {
                        const userDate = new Date(user.createdAt.seconds * 1000).toLocaleDateString();
                        return userDate === label;
                    }).length,
                    docs: data.docsData.filter(doc => {
                        const docDate = new Date(doc.timestamp.seconds * 1000).toLocaleDateString();
                        return docDate === label;
                    }).length,
                    payments: data.docPaymentData.filter(payment => {
                        const paymentDate = new Date(payment.createdDate.seconds * 1000).toLocaleDateString();
                        return paymentDate === label;
                    }).length,
                });
            }
        } else if (range === 'yearly') {
            for (let i = 11; i >= 0; i--) {
                const date = new Date();
                date.setMonth(now.getMonth() - i);
                const label = `${date.getMonth() + 1}/${date.getFullYear()}`;
                chartData.push({
                    date: label,
                    users: data.usersData.filter(user => {
                        const userDate = new Date(user.createdAt.seconds * 1000);
                        return `${userDate.getMonth() + 1}/${userDate.getFullYear()}` === label;
                    }).length,
                    docs: data.docsData.filter(doc => {
                        const docDate = new Date(doc.timestamp.seconds * 1000);
                        return `${docDate.getMonth() + 1}/${docDate.getFullYear()}` === label;
                    }).length,
                    payments: data.docPaymentData.filter(payment => {
                        const paymentDate = new Date(payment.createdDate.seconds * 1000);
                        return `${paymentDate.getMonth() + 1}/${paymentDate.getFullYear()}` === label;
                    }).length,
                });
            }
        }

        return chartData;
    };

    return (
        <DataContext.Provider value={{ docs, data, range, setRange }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
