import React from 'react';
import { Typography, Container, Box, Grid, Accordion,
  AccordionSummary,
  AccordionDetails,
  Card, CardContent
  } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';  
import ToolbarUnAuthComponent from './ToolbarUnAuthComponent';
import FeatureImage1 from '../assets/featureImage1.svg'; // Add your feature images
import FeatureImage2 from '../assets/featureImage1.svg';
import FeatureImage3 from '../assets/featureImage3.svg';
import FeatureImage4 from '../assets/featureImage4.svg';
// import MobileApp1  from '../assets/mobile-app-1.png'





export default function LandingPage() {

  const faqs = [
    {
      question: "What is Madito?",
      answer: "Madito is a document lost and found app that helps people recover their lost documents by connecting them with those who have found them."
    },
    {
      question: "How does Madito work?",
      answer: "Users can submit lost or found documents through the app. If a match is found, the document's owner is notified, and arrangements are made to return the document."
    },
    {
      question: "Is Madito secure?",
      answer: "Yes, Madito uses industry-standard security measures, including encryption and secure data storage, to ensure the safety and privacy of your information. Madito will also automatically delete the document from our storage upon successful return."
    },
    {
      question: "How can I get paid for submitting a found document?",
      answer: "You can choose to receive payments via EFT or Cashsend/Ewallet. Simply provide your payment details when submitting the document. Payments are only released after successful return of the document."
    },
    {
      question: "What types of documents can be reported?",
      answer: "Madito supports various document types, including IDs, passports, driver's licenses, and more."
    },
    {
      question: "Can I delete my account?",
      answer: "Yes, users have the option to delete their account and all associated data at any time through the app's settings."
    },
    {
      question: "How will I know if my document is found?",
      answer: "If your document is found, you will receive an alert through the app, via SMS or email, depending on your preferred contact method. You will only be alerted of the most recent document search performed through the Madito app."
    },
  ];

  const testimonials = [
  {
    name: "Lenkoe Makotoko",
    comment: "I never thought I'd get my passport back, but thanks to Madito, I did! I can't recommend this app enough.",
    position: "Founder Senat Marketing"
  },
  {
    name: "Leticia Nkulu",
    comment: "As someone who frequently loses things, Madito has been a lifesaver. I love the user-friendly interface and quick support.",
    position: "Founder Megastore"
  },
  {
    name: "Eric Muamba",
    comment: "Madito's service is top-notch. I found someone's driver's license, and the reward process was quick and easy.",
    position: "Security Guard"
  },
];
  
  return (
    <div>
      <ToolbarUnAuthComponent />

      <Container>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography 
            variant="h3"
          component="h1"
            sx={{
              fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
              fontWeight: 'bold',
              marginBottom: 2,
            }}
            gutterBottom>
            What is Madito?
          </Typography>
          <Typography 
            variant="h7"
            sx={{fontSize: { xs: '1rem', sm: '1.25rem', md: '1.1rem' },}}
            component="div">
            Welcome to Madito, a document lost and found app designed to help users recover their lost documents. We also give royalies to good citizens for returning lost and found documents
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={FeatureImage4}
            alt="A lady having a phone in her hand smiling."
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>
      </Grid>
    </Container>

    <Container maxWidth="md">
      <Box
        sx={{
          textAlign: 'center',
          padding: { xs: 2, sm: 4, md: 6 },
          margin: 'auto',
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
            fontWeight: 'bold',
            marginBottom: 2,
          }}
        >
         Most Advanced Document Detectives
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: '1rem', sm: '1.25rem', md: '1.1rem' },
            marginTop: 2,
          }}
        >
          Cut the long queues, early mornings and long waiting periods for document renewals. Get it back today. After all, it's still valid.
        </Typography>
      </Box>
    </Container>

    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={FeatureImage1}
            alt="Madito Image"
            sx={{
              width: '90%',
              height: 'auto',
              borderRadius: 2,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            Why Madito
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Your Go-To Solution for Document Recovery
          </Typography>
          <Typography variant="body1">
            Madito is a revolutionary app designed to make recovering lost documents easier and more secure than ever. 
            With Madito, you no longer need to worry about the stress and hassle of losing important documents. 
            Our platform connects you with a community of users who help find and return lost documents quickly and efficiently. 
            {/* Whether it’s a passport, ID, or driver’s license, Madito provides a reliable and user-friendly solution to get your documents back in your hands. 
            Join us in making the world a more connected and responsible place, one document at a time. */}
          </Typography>
        </Grid>
      </Grid>
    </Container>

    <Box sx={{ backgroundColor: '#f5f5f5', py: 4 }}> {/* Section Background Color */}
    <Container maxWidth="lg" sx={{ py: 4 }} > {/* backgroundColor="primary" */}
      <Typography variant="h4" component="h2" gutterBottom>
        What Our Users Are Saying
      </Typography>
      <Grid container spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Card elevation={3}>
              <CardContent>
                <Typography variant="body1" gutterBottom>
                  "{testimonial.comment}"
                </Typography>
                <Box mt={2}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {testimonial.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {testimonial.position}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    </Box>

    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`faq-${index}-content`}
            id={`faq-${index}-header`}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
    

      <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: (theme) => theme.palette.grey[200] }}>
        <Container maxWidth="sm">
          <Typography variant="body1">
            © 2024 Madito Mobile App. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </div>
  );
}