import React, { useEffect, useState } from 'react';
import { useData } from '../contexts/DataContext';
import { Button, ButtonGroup, Box, Typography, Card, CardContent } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function TotalAmountPaidOut() {
    const { docs, range, setRange } = useData();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (docs.length > 0) {
            const processedData = processChartData(docs, range);
            setChartData(processedData);
        }
    }, [docs, range]);

    const processChartData = (data, range) => {
        const chartData = [];
        const now = new Date();

        const convertCentsToUnits = (amountInCents) => (amountInCents || 0) / 100;

        if (range === 'weekly') {
            for (let i = 6; i >= 0; i--) {
                const date = new Date();
                date.setDate(now.getDate() - i);
                const label = date.toLocaleDateString();

                const dailyData = data.filter(doc => {
                    const docDate = new Date(doc.timestamp.seconds * 1000).toLocaleDateString();
                    return docDate === label;
                });

                const totalPaidOut = dailyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.commission), 0);
                const totalPaid = dailyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.amountPaid), 0);
                const totalIncome = dailyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.serviceFees), 0);

                chartData.push({
                    date: label,
                    paidOut: totalPaidOut.toFixed(2),
                    paid: totalPaid.toFixed(2),
                    income: totalIncome.toFixed(2),
                });
            }
        } else if (range === 'monthly') {
            for (let i = 29; i >= 0; i--) {
                const date = new Date();
                date.setDate(now.getDate() - i);
                const label = date.toLocaleDateString();

                const dailyData = data.filter(doc => {
                    const docDate = new Date(doc.timestamp.seconds * 1000).toLocaleDateString();
                    return docDate === label;
                });

                const totalPaidOut = dailyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.commission), 0);
                const totalPaid = dailyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.amountPaid), 0);
                const totalIncome = dailyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.serviceFees), 0);

                chartData.push({
                    date: label,
                    paidOut: totalPaidOut.toFixed(2),
                    paid: totalPaid.toFixed(2),
                    income: totalIncome.toFixed(2),
                });
            }
        } else if (range === 'yearly') {
            for (let i = 11; i >= 0; i--) {
                const date = new Date();
                date.setMonth(now.getMonth() - i);
                const label = `${date.getMonth() + 1}/${date.getFullYear()}`;

                const monthlyData = data.filter(doc => {
                    const docDate = new Date(doc.timestamp.seconds * 1000);
                    return `${docDate.getMonth() + 1}/${docDate.getFullYear()}` === label;
                });

                const totalPaidOut = monthlyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.commission), 0);
                const totalPaid = monthlyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.amountPaid), 0);
                const totalIncome = monthlyData.reduce((sum, doc) => sum + convertCentsToUnits(doc.serviceFees), 0);

                chartData.push({
                    date: label,
                    paidOut: totalPaidOut.toFixed(2),
                    paid: totalPaid.toFixed(2),
                    income: totalIncome.toFixed(2),
                });
            }
        }

        return chartData;
    };

    return (
        <Card>
            <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" gutterBottom sx={{ color: 'grey.600' }}>
                        Financial Overview
                    </Typography>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group" size="small">
                        <Button onClick={() => setRange('weekly')}>Weekly</Button>
                        <Button onClick={() => setRange('monthly')}>Monthly</Button>
                        <Button onClick={() => setRange('yearly')}>Yearly</Button>
                    </ButtonGroup>
                </Box>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis 
                            label={{ value: 'Amount (R)', angle: -90, position: 'insideLeft' }}
                            domain={['auto', 'auto']}
                        />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="paidOut" stroke="#8884d8" name="Paid Out" />
                        <Line type="monotone" dataKey="paid" stroke="#82ca9d" name="Paid" />
                        <Line type="monotone" dataKey="income" stroke="#ffc658" name="Income" />
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}
