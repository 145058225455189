import React from 'react';
import PrimarySearchAppBar from './AppbarHome';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PaymentComponent from './PaymentComponent';
import UnAssignedPayoutComponent from './UnAssignedPayoutComponnet';
import AssignedPaymentComponent from './AssignedPaymentComponent';
import CompletedPaymentComponent from './CompletedPaymentComponent';
import { Typography, Grid, Container } from '@mui/material';

import CustomLineChart from './graphs/CustomLineChart';
import TotalAmountPaid from './graphs/TotalAmountPaidOut';
import DocumentViewRate from './graphs/DocumentViewRate';
import DocumentTypeDistribution from './graphs/DocumentTypeDistribution';



const HomePage = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <PrimarySearchAppBar />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="DASHBOARD" value="1" />
              <Tab label="UNASSIGNED PAYOUTS" value="2" />
              <Tab label="ASSIGNED TO ME" value="3" />
              <Tab label="COMPLETED PAYOUT" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {/* <Box sx={{backgroundColor: 'primary'}}>
              <CustomLineChart />
            </Box> */}

              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <CustomLineChart />
                  {/* <TotalAmountPaid /> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TotalAmountPaid />
                  {/* <DocumentSubmissionByType /> */}
                  
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <CommissionEarned /> */}
                  <DocumentTypeDistribution/>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <DocumentStatus /> */}
                  <CustomLineChart />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DocumentViewRate />
                </Grid>
                <Grid item xs={12} md={6}>
                </Grid>
              </Grid>
            
            {/* <PaymentComponent /> */}
          </TabPanel>
          <TabPanel value="2">
          <p>*After successful return of document. a new record is created here. Assign it to yourself and make the mapyment to document Finder. payments must be completed in 48 hours.* </p>
            <UnAssignedPayoutComponent />
          </TabPanel>
          <TabPanel value="3">
            <AssignedPaymentComponent />
          </TabPanel>
          <TabPanel value="4">
            <CompletedPaymentComponent />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default HomePage;