import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure you have your Firebase config here
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
export default function CompletedPaymentComponent() {
   const [docs, setDoc] = useState([]);

  useEffect(() => {
   try{
     const q = query(collection(db, 'docs'), 
    where('paymentStatus', '==', 'completed'), 
    orderBy('paidDate', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const paymentsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDoc(paymentsData);
    });

    return () => unsubscribe();
   }catch(error) {
    console.log(error)
   }
  }, []);

  const handleViewDocument = (documentID) => {
    // Implement your view document logic here
    console.log(`View document with ID: ${documentID}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Actions</TableCell>
            {/* <TableCell>Total Cost</TableCell> */}
            <TableCell>Payment Reference</TableCell>
            <TableCell>Paid Founder</TableCell>
            <TableCell>Paid Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {docs.map((doc) => (
            <TableRow key={doc.id}>
             <TableCell>
               <Button variant="contained" color="primary" onClick={() => handleViewDocument(doc.id)}>
                 View
               </Button>
              </TableCell>
              {/* <TableCell>{doc.amountPaid / 100}</TableCell> */}
              <TableCell>{doc.idRefNumber}</TableCell>
              <TableCell>{doc.commission/100}</TableCell>
              <TableCell>{doc.completedAt.toDate().toLocaleDateString()}</TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
