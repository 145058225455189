import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAcfR3D8e3-0VssyuG024J2Qs-QEpMHtNQ",
  authDomain: "madito-33532.firebaseapp.com",
  projectId: "madito-33532",
  storageBucket: "madito-33532.appspot.com",
  messagingSenderId: "801990854526",
  appId: "1:801990854526:web:0b827b7e3ad9138dd1152f",
  measurementId: "G-7XDPYRCFWH"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };