import React, { useEffect, useState } from 'react';
import { useData } from '../contexts/DataContext';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer, Legend } from 'recharts';
import { Card, CardContent, Typography } from '@mui/material';

export default function DocumentTypeDistribution() {
    const { docs } = useData();
    const [chartData, setChartData] = useState([]);

    const documentTypes = ['Driver\'s License', 'Smart ID', 'Foreign Passport', 'Green Barcoded ID Book', 'SA Passport'];
    const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1'];

    useEffect(() => {
        if (docs.length > 0) {
            const processedData = documentTypes.map((type, index) => ({
                name: type,
                value: docs.filter(doc => doc.documentType === type).length,
                color: COLORS[index % COLORS.length],
            }));
            setChartData(processedData);
        }
    }, [docs]);

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" gutterBottom sx={{ color: 'grey.600' }}>
                    Document Type Distribution
                </Typography>
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie
                            data={chartData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            fill="#8884d8"
                            label
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}
