import React from 'react';
import { Box, Typography, Link } from '@mui/material';

export default function PrivacyComponent() {
  return (
    <Box
      sx={{
        padding: 4,
        maxWidth: 800,
        margin: 'auto',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Privacy Policy for Madito
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Effective Date:</strong> [25/05/2024]
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Introduction
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to Madito, a document lost and found app that helps users recover their lost documents. We are committed to protecting your privacy and ensuring that your personal information is handled with care. This Privacy Policy explains how we collect, use, and protect your information.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        We collect the following information from our users:
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Username:</strong> User name does not have to a names found on your identity document. We use this username to identity our users and this name will not be displayed anywhere inside our application or to any user of our mobile application.
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Email Address:</strong> Used to communicate with users when a document is recovered. THe email address will not be shared with or displayed on our mobile application.
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Phone Number:</strong> Used to create a user account and to alert users when their document is found. .
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Payment Information:</strong> If users choose to be rewarded via EFT, we collect their bank name, account number, and branch code. This information will not be shared with third party.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. How We Collect Information
      </Typography>
      <Typography variant="body1" paragraph>
        All information is collected directly from users through forms provided within the app.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Purpose of Data Collection
      </Typography>
      <Typography variant="body1" paragraph>
        The information we collect is used to:
      </Typography>
      <Typography variant="body1" paragraph>
        - Enhance user experience.
      </Typography>
      <Typography variant="body1" paragraph>
        - Alert users when their documents are found.
      </Typography>
      <Typography variant="body1" paragraph>
        - Process payments for users who find and submit documents.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Data Sharing
      </Typography>
      <Typography variant="body1" paragraph>
        We only share your data with third-party services necessary for the operation of Madito:
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Yoco Payment Gateway:</strong> Handles payments when users make a payment.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Data Storage and Security
      </Typography>
      <Typography variant="body1" paragraph>
        Your data is securely stored in the cloud using Google Cloud services. We have implemented robust security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. User Rights
      </Typography>
      <Typography variant="body1" paragraph>
        You have the right to:
      </Typography>
      <Typography variant="body1" paragraph>
        - Update or correct your personal information.
      </Typography>
      <Typography variant="body1" paragraph>
        - Delete your data or your account at any time through the app.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Data Retention
      </Typography>
      <Typography variant="body1" paragraph>
        We retain your data for as long as your account is active. Once you delete your account, your data will be permanently removed from our systems.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Third-Party Services
      </Typography>
      <Typography variant="body1" paragraph>
        We use the following third-party services:
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Cloud Services</strong>.
      </Typography>
      <Typography variant="body1" paragraph>
        - <strong>Yoco Payment Gateway:</strong> For handling payments.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Cookies and Tracking
      </Typography>
      <Typography variant="body1" paragraph>
        Madito does not use cookies or any tracking technologies to monitor user activities.
      </Typography>

      <Typography variant="h6" gutterBottom>
        11. Children’s Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Madito is intended for users who are 16 years of age or older. We do not knowingly collect personal information from children under 16. If we become aware that a user under 16 has provided us with personal information, we will take steps to delete such information.
      </Typography>

      <Typography variant="h6" gutterBottom>
        12. Changes to this Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Any changes to our Privacy Policy will be communicated via a blog post on our website and through our social media channels, including our Twitter account.
      </Typography>

      <Typography variant="h6" gutterBottom>
        13. Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Privacy Policy or your data, please contact us at:{' '}
        <Link href="mailto:support@madito.io">support@madito.io</Link>
      </Typography>
    </Box>
  );
}
