import React, { useEffect, useState } from 'react';
import { collection, doc, onSnapshot, orderBy, query, where, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Ensure you have your Firebase config here
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, AppBar, Toolbar, IconButton, Typography, List, ListItemText, Divider, ListItemButton, Dialog, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Timestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignedPayoutComponent() {
  const [docs, setDocs] = useState([]);
  const [loadingButtons, setLoadingButtons] = useState({});
  const [open, setOpen] = useState(false);
  const [docInfo, setDocInfo] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [currentDoc, setCurrentDoc] = useState();

  useEffect(() => {
    try {
      const q = query(collection(db, 'docs'), 
        where('completed', '==', true),
        where('paymentStatus', '==', ''),
        where('assignedTo', '==', auth.currentUser.uid), 
        orderBy('paidDate', 'desc'));
      
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const paymentsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setDocs(paymentsData);
      });

      return () => unsubscribe();
    } catch(error) {
      console.log(error)
    }
  }, []);

  const getTimeDifference = (completedAt) => {
    const currentTime = Timestamp.now();
    const completedTime = completedAt.toDate();

    const differenceInMilliseconds = currentTime.toMillis() - completedTime.getTime();

    const seconds = Math.floor(differenceInMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return `${days} days, ${hours % 24} hours, ${minutes % 60} minutes ago`;
  };

const handleAssign = async (document) => {
    setLoadingButtons((prev) => ({ ...prev, [document.id]: true }));
    try {
      // const loggedInUserId = auth.currentUser.uid;

      if (!document.id) {
        throw new Error('empty-value', 'Please select a document.');
      }
      setDocInfo(document);
      setCurrentDoc(document)

      const founderUID = document.founder;
      const accountRef = doc(collection(db, 'accounts'), founderUID);
      const accountSnap = await getDoc(accountRef);

      if (accountSnap.exists()) {
          setAccountInfo(accountSnap.data());
      } else {
          // console.error('No account found for the founder');
      }

      setOpen(true); // Open the dialog

    } catch (error) {
      // console.error('Error updating document: ', error);
    } finally {
      setLoadingButtons((prev) => ({ ...prev, [document.id]: false }));
    }
  };


  const handleClose = () => {
    setOpen(false);
    setDocInfo(null);
    setAccountInfo(null);
  };

  const handleSave = async() => {

    try {
     
      if (!currentDoc) {
        throw new Error('empty-value', 'Please select a document.');
      }
      const functions = getFunctions();
      const savePayment = httpsCallable(
        functions,
        "savePayment"
      );

      const data = {
      founderId: currentDoc.founder,
      ownder: currentDoc.owner,
      documentID: currentDoc.id,
      amountPaid: currentDoc.commission,
      referenceNumber: currentDoc.idRefNumber
      }

      const response = await savePayment(data);

      if (response.data.success){
        handleClose();
      }
    } catch (error) {
      console.error( error);
    } finally {
      setLoadingButtons((prev) => ({ ...prev, [currentDoc.id]: false }));
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Actions</TableCell>
            <TableCell>Payment Amount</TableCell>
            <TableCell>Document ID</TableCell>
            <TableCell>Waiting Time</TableCell>
            <TableCell>Completed Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {docs.map((doc) => (
            <TableRow key={doc.id}>
              <TableCell>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleAssign(doc)}
                  disabled={loadingButtons[doc.id]}
                >
                  {loadingButtons[doc.id] ? 'Loading...' : 'Process Payment'}
                </Button>
              </TableCell>
              <TableCell>ZAR {doc.commission/100}</TableCell>
              <TableCell>{doc.idRefNumber}</TableCell>
              <TableCell>{getTimeDifference(doc.completedAt)}</TableCell>
              <TableCell>{doc.paidDate.toDate().toLocaleDateString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Document and Account Information
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {docInfo && (
            <>
              <ListItemButton>
                <ListItemText primary="Amount to be Transfered to finder" secondary={`ZAR ${docInfo.commission/100}`} />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Document ID" secondary={docInfo.idRefNumber} />
              </ListItemButton>
              <Divider />
            </>
          )}
          {accountInfo && (
            <>
              <ListItemButton>
                <ListItemText primary="Payment Method" secondary={accountInfo.paymentMethod} />
              </ListItemButton>
              {accountInfo.paymentMethod === 'Ewallet' ? (
                <>
                  <ListItemButton>
                    <ListItemText primary="Phone Number" secondary={accountInfo.phoneNumber} />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText primary="PIN" secondary={accountInfo.pin} />
                  </ListItemButton>
                </>
              ) : (
                <>
                  <ListItemButton>
                    <ListItemText primary="Account Number" secondary={accountInfo.accountNumber} />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText primary="Bank Name" secondary={accountInfo.bankName} />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText primary="Branch Code" secondary={accountInfo.branchCode} />
                  </ListItemButton>
                </>
              )}
              <Divider />
            </>
          )}
        </List>
      </Dialog>
    </TableContainer>
  );
}
