import React from 'react';
import { Box, Typography, Link } from '@mui/material';

export default function TermsComponent() {
  return (
    <Box
      sx={{
        padding: 4,
        maxWidth: 800,
        margin: 'auto',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Terms and Conditions for Madito
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Effective Date:</strong> [25/05/2024]
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Introduction
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to Madito, a document lost and found app designed to help users recover their lost documents. By using our app, you agree to these Terms and Conditions. Please read them carefully.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing or using Madito, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please do not use the app.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. User Eligibility
      </Typography>
      <Typography variant="body1" paragraph>
        Madito is intended for users who are 16 years of age or older. By using the app, you confirm that you meet this age requirement.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Account Creation
      </Typography>
      <Typography variant="body1" paragraph>
        To use certain features of the app, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. User Responsibilities
      </Typography>
      <Typography variant="body1" paragraph>
        You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Use of the App
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to use Madito only for lawful purposes and in accordance with these Terms and Conditions. You must not use the app:
      </Typography>
      <Typography variant="body1" paragraph>
        - In any way that violates any applicable local, national, or international law.
      </Typography>
      <Typography variant="body1" paragraph>
        - To exploit, harm, or attempt to exploit or harm minors.
      </Typography>
      <Typography variant="body1" paragraph>
        - To transmit or procure the sending of any unsolicited or unauthorized advertising or promotional material.
      </Typography>
      <Typography variant="body1" paragraph>
        - To exchange any contact information, including but not limited to phone numbers, email addresses, or social media links, via our chat system. Sharing such information will automatically disqualify you from receiving a refund.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Payments and Rewards
      </Typography>
      <Typography variant="body1" paragraph>
        If you choose to receive rewards for finding and submitting documents, you may provide payment information. We use Yoco Payment Gateway for processing payments. You agree to provide accurate payment information and acknowledge that we are not responsible for any issues arising from the use of third-party payment services.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Refund Policy:</strong> Users are advised to contact support if they fail to meet any requirements. Our support team will attempt to reach out to the other user involved. Refunds are subject to service fees, bank charges, and other applicable costs.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Data Collection and Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        We collect and use your personal information as described in our{' '}
        <Link href="privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>. By using the app, you consent to the collection and use of your data in accordance with our Privacy Policy.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to terminate or suspend your account and access to the app at our sole discretion, without notice, for any reason, including if you breach these Terms and Conditions.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Intellectual Property
      </Typography>
      <Typography variant="body1" paragraph>
        All content, features, and functionality on the app are the exclusive property of Madito and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from the app without our prior written permission.
      </Typography>

      <Typography variant="h6" gutterBottom>
        11. Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" paragraph>
        Madito is provided "as is" and "as available" without any warranties of any kind. We do not warrant that the app will be uninterrupted, error-free, or free of harmful components.
      </Typography>

      <Typography variant="h6" gutterBottom>
        12. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        To the fullest extent permitted by law, Madito shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of the app or any content or services provided through the app.
      </Typography>

      <Typography variant="h6" gutterBottom>
        13. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms and Conditions are governed by and construed in accordance with the laws of South Africa. Any disputes arising out of or related to these terms shall be resolved in the courts of South Africa.
      </Typography>

      <Typography variant="h6" gutterBottom>
        14. Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We may update these Terms and Conditions from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the app after any changes indicates your acceptance of the new terms.
      </Typography>

      <Typography variant="h6" gutterBottom>
        15. Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about these Terms and Conditions, please contact us at:{' '}
        <Link href="mailto:support@madito.io">support@madito.io</Link>
      </Typography>
    </Box>
  );
}

